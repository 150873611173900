<template>
  <div class="sysuser">
    <SystemMenu />
    <!-- 筛选项 -->
    <div class="wrap">
      <el-form :inline="true">
        <!-- <el-form-item label="用户名">
          <el-input
            size="small"
            placeholder="请输入用户名"
            clearable
          ></el-input>
        </el-form-item> -->
        <el-form-item>
          <el-button size="small" icon="el-icon-refresh" @click="getUserListData">刷新</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 功能按钮 -->
    <div class="wrap-blank">
      <el-button size="small" plain type="primary" icon="el-icon-plus" @click="onAddUser"
        >添加</el-button
      >
    </div>

    <!-- 数据列表 -->
    <div class="wrap">
      <el-table :data="userListData" @row-click="setCurrentRow" style="width: 100%">
        <el-table-column prop="userId" label="ID" width="180">
        </el-table-column>
        <el-table-column prop="userName" label="用户名" width="180">
        </el-table-column>
        <el-table-column prop="roleNmae" label="用户角色"> </el-table-column>
        <el-table-column prop="createTime" label="创建时间"> </el-table-column>
        <el-table-column label="操作" width="400">
          <template slot-scope="scope">
            <el-button
              size="mini"
              icon="el-icon-edit"
              @click="onEditUser(scope.row)"
              :disabled="isCurrentUser(scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="onDelUser(scope.row)"
              :disabled="isCurrentUser(scope.row)"
              >删除</el-button
            >
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-office-building"
              @click="userProjectOpen(scope.row)"
              :disabled="isCurrentUser(scope.row)"
              >绑定项目</el-button
            >
            <el-button
              size="mini"
              type="warning"
              icon="el-icon-s-operation"
              @click="menuPermissionOpen(scope.row)"
              :disabled="isCurrentUser(scope.row)"
              >菜单权限</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        @current-change="getUserListData"
        :current-page.sync="pageNum"
        :page-size="pageSize"
        layout="total, prev, pager, next"
        :total="totals"
      >
      </el-pagination>
    </div>

    <!-- 添加、编辑用户 -->
    <el-dialog
      :title="userFormTitle"
      :visible.sync="userFormShow"
      width="500px"
      :before-close="userFormClose">

      <el-descriptions title="个人资料" labelClassName="labelClassName" :column="1" border>
        <el-descriptions-item label="用户名">
          <el-input v-model="userFormData.accountName" placeholder="请输入内容" clearable></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="用户角色">
          <el-radio v-model="userFormData.role" label="1" disabled>超级管理员</el-radio>
          <el-radio v-model="userFormData.role" label="2">管理员</el-radio>
          <el-radio v-model="userFormData.role" label="3">普通用户</el-radio>
        </el-descriptions-item>
        <el-descriptions-item label="手机号">
          <el-input v-model="userFormData.phone" placeholder="请输入内容" clearable></el-input>
        </el-descriptions-item>
      </el-descriptions>

      <div style="height: 20px"></div>

      <el-descriptions title="登录密码" labelClassName="labelClassName" :column="1" border>
        <el-descriptions-item label="密码">
          <el-input v-model="userFormData.password" placeholder="请输入内容" show-password clearable></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="确认密码">
          <el-input v-model="userFormData.repassword" placeholder="请输入内容" show-password clearable></el-input>
        </el-descriptions-item>
      </el-descriptions>
      <div v-if="userFormMode == 'edit'" style="margin-top:6px;">
        如无需修改密码此项请留空
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="userFormClose">取 消</el-button>
        <el-button type="primary" @click="onSaveUserFormData">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 绑定项目弹窗 -->
    <el-dialog
      title="请选择绑定项目"
      :visible.sync="userProjectShow"
      width="800px"
      :before-close="userProjectClose">
        <el-table
          ref="projectTable"
          :data="projectList"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleProjectChange">
          <el-table-column
            type="selection"
            :selectable="projectListFilter"
            width="55">
          </el-table-column>
          <el-table-column
            prop="proName"
            label="项目名称">
          </el-table-column>
        </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="userProjectClose">取 消</el-button>
        <el-button type="primary" @click="userProjectSave">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 菜单权限弹窗 -->
    <el-dialog
      title="请配置菜单权限"
      :visible.sync="menuPermissionShow"
      width="1000px"
      :before-close="menuPermissionClose">

      <el-descriptions title="菜单列表" labelClassName="labelClassName" :column="1" border>
        <el-descriptions-item label="首页">
          <el-checkbox v-model="menuPermissionData.Home" disabled>首页</el-checkbox>
        </el-descriptions-item>
        <el-descriptions-item label="项目综合">
          <el-checkbox v-model="menuPermissionData.Project.ProjectList" disabled>项目管理</el-checkbox>
        </el-descriptions-item>
        <el-descriptions-item label="参建单位管理">
          <el-checkbox v-model="menuPermissionData.Organization.Unit">参建单位</el-checkbox>
          <el-checkbox v-model="menuPermissionData.Organization.Department">部门/班组</el-checkbox>
          <el-checkbox v-model="menuPermissionData.Organization.Personnel">员工花名册</el-checkbox>
        </el-descriptions-item>
        <el-descriptions-item label="考勤管理">
          <el-checkbox v-model="menuPermissionData.Attendance.AttendancePerson">考勤人员</el-checkbox>
          <el-checkbox v-model="menuPermissionData.Attendance.AttendanceConfig">考勤设置</el-checkbox>
          <el-checkbox v-model="menuPermissionData.Attendance.WorkTimeList">排班管理</el-checkbox>
          <el-checkbox v-model="menuPermissionData.Attendance.SetWorkTime">上班安排</el-checkbox>
          <el-checkbox v-model="menuPermissionData.Attendance.QueryDay">日统计</el-checkbox>
          <el-checkbox v-model="menuPermissionData.Attendance.QueryMonth">月统计</el-checkbox>
        </el-descriptions-item>
        <el-descriptions-item label="设备管理">
          <el-checkbox v-model="menuPermissionData.Device.TowerCrane">塔机设备</el-checkbox>
          <el-checkbox v-model="menuPermissionData.Device.DischargingPlatform">卸料设备</el-checkbox>
          <el-checkbox v-model="menuPermissionData.Device.Elevator">升降机设备</el-checkbox>
          <el-checkbox v-model="menuPermissionData.Device.ElectricityMonitoring">用电设备</el-checkbox>
          <el-checkbox v-model="menuPermissionData.Device.DustMonitoring">扬尘设备</el-checkbox>
          <el-checkbox v-model="menuPermissionData.Device.IntelligenceEye">智眸AI</el-checkbox>
        </el-descriptions-item>
        <el-descriptions-item label="视频管理">
          <el-checkbox v-model="menuPermissionData.Video.VideoList">视频管理</el-checkbox>
        </el-descriptions-item>
        <el-descriptions-item label="短信预警">
          <el-checkbox v-model="menuPermissionData.Message.MessageList">短信预警管理</el-checkbox>
        </el-descriptions-item>
        <el-descriptions-item label="系统设置">
          <el-checkbox v-model="menuPermissionData.System.MyConfig" disabled>个人设置</el-checkbox>
          <el-checkbox v-model="menuPermissionData.System.SysUser" :disabled="isUser">用户管理</el-checkbox>
        </el-descriptions-item>
        <el-descriptions-item label="退出">
          <el-checkbox v-model="menuPermissionData.Login" disabled>退出</el-checkbox>
        </el-descriptions-item>
      </el-descriptions>
        
      <span slot="footer" class="dialog-footer">
        <el-button v-if="roleLevel == '1'" type="warning" plain @click="menuPermissionInit">初始化菜单权限</el-button>
        <el-button @click="menuPermissionClose">取 消</el-button>
        <el-button type="primary" @click="menuPermissionSave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<style lang="less" scope>
.sysuser {
  .el-form-item {
    margin-bottom: 0;
  }
  .unit-item {
    margin-bottom: 20px;
    border-bottom: 1px dashed #d5d9e4;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .device-record {
    width: 1200px;
    background-color: #f0f0f0;
  }
  .video-player {
    width: 1200px;
  }
  .my-label {
    width: 20%;
  }
  .my-content {
    width: 30%;
  }
  .el-date-editor {
    width: 100% !important;
  }
  .labelClassName {
    width: 120px;
  }
}
</style>

<script>
import SystemMenu from "@/components/SystemMenu.vue";

export default {
  name: "SysUser",
  components: {
    SystemMenu,
  },
  data() {
    return {
      userListData: null,
      pageNum: 1,
      pageSize: 10,
      totals: 0,
      // username:'',
      userFormMode: null,
      userFormShow: false,
      userFormData: {
        userId: null,
        accountName: null,
        role: null,
        password: null,
        repassword: null,
        phone: null,
        menuPermission: null,
        projectIdList: [],
      },

      currentRow: {userName:''},

      projectList: [],
      userProjectShow: false,
      projectSelection: [],

      menuPermissionShow: false,
      menuPermissionData: {
        Home: true,
        Project: {
          ProjectList: true,
        },
        Organization: {
          Unit: false,
          Department: false,
          Personnel: false,
        },
        Attendance: {
          AttendancePerson: false,
          AttendanceConfig: false,
          WorkTimeList: false,
          SetWorkTime: false,
          QueryDay: false,
          QueryMonth: false,
        },
        Device: {
          TowerCrane: false,
          DischargingPlatform: false,
          Elevator: false,
          ElectricityMonitoring: false,
          DustMonitoring: false,
          IntelligenceEye: false,
        },
        Video: {
          VideoList: false,
        },
        Message: {
          MessageList: false,
        },
        System: {
          MyConfig: true,
          SysUser: false,
        },
        Login: true
      },
      isUser: true,

      roleLevel: localStorage.getItem('roleLevel')
    };
  },
  computed: {
    userFormTitle: function () {
      if (this.userFormMode == 'add') {
        return '添加用户'
      }
      if (this.userFormMode == 'edit') {
        return '编辑用户'
      }
    },
    projectIdList: function () {
      var arr = []
      this.projectList.forEach((item, index) => {
        arr.push(item.id)
      })
      return arr
    }
  },
  mounted() {
    // this.getUserListData(); // 获取用户列表数据
    this.getProjectList() // 获取当前用户已绑定的项目列表
  },
  watch: {
    currentRow: {
      handler: function (newVal, oldVal) {
        console.log('watch currentRow =>', newVal);
        if (!newVal) {
          return
        }
        if (newVal.roleNmae == '超级管理员' || newVal.roleNmae == '管理员') {
          this.isUser = false
        } else {
          this.isUser = true
        }
      },
      deep: true
    }
  },
  methods: {
    // 获取用户列表数据
    getUserListData: function () {
      var that = this;
      var data = {
        projectIdList: this.projectIdList,
        pageNum: that.pageNum,
        pageSize: that.pageSize,
      };
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/user/userList",
          data: JSON.stringify(data),
        })
        .then((res) => {
          console.log("getTableData =>", res);
          that.totals = res.data.totals;
          that.userListData = res.data.userAndRoleList;
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 点击表格行公共事件
    setCurrentRow: function (row) {
      console.log('setCurrentRow =>' ,row);
      this.currentRow = row
    },
    // 删除用户
    onDelUser: function (row) {
      var that = this
      console.log('delUser =>', row);
      this.$confirm('此操作将删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var data = {
          userId: row.userId
        }
        that.loading = that.$loading({
          lock: true,
          text: "Loading...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        that
          .$axiosAdmin({
            method: "post",
            url: 'api/admin/user/userDel',
            data: JSON.stringify(data),
          })
          .then((res) => {
            console.log("onDelUser =>", res);
            that.getUserListData()
            that.loading.close();
          })
          .catch((err) => {
            console.log(err);
            that.loading.close();
          });
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });          
      });
    },
    // 编辑用户
    onEditUser: function (row) {
      console.log('onEditUser =>', row);
      this.userFormShow = true
      this.userFormMode = 'edit'
      this.userFormData.userId = row.userId
      this.userFormData.accountName = row.userName
      this.userFormData.role = this.getRole(row.roleNmae)
      this.userFormData.phone = row.phone
      
    },
    // 添加用户
    onAddUser: function () {
      this.userFormShow = true
      this.userFormMode = 'add'
      this.userFormData.userId = null
      this.userFormData.accountName = null
      this.userFormData.role = null
      this.userFormData.password = null
      this.userFormData.repassword = null
      this.userFormData.phone = null
      this.userFormData.menuPermission = null
    },
    // 关闭用户编辑/添加窗口
    userFormClose: function () {
      this.userFormShow = false
      this.userFormMode = null

      this.userFormData.userId = null
      this.userFormData.accountName = null
      this.userFormData.role = null
      this.userFormData.password = null
      this.userFormData.repassword = null
      this.userFormData.phone = null
      this.userFormData.menuPermission = null
    },
    // 获取用户角色值
    getRole: function (str) {
      if (str == '超级管理员') {
        return "1"
      }
      if (str == '管理员') {
        return "2"
      }
      if (str == '普通用户') {
        return "3"
      }
    },
    // 保存 添加/编辑 用户表单的数据
    onSaveUserFormData: function () {
      var that = this
      if (!that.userFormData.accountName) {
        that.$message('请填写用户名');
        return
      }
      if (!that.userFormData.role) {
        that.$message('请选择用户角色');
        return
      }
      if (!that.userFormData.phone) {
        that.$message('请填写用户电话');
        return
      }
      if (that.userFormData.password != that.userFormData.repassword) {
        that.$message('确认密码不一致');
        return
      }
      if (this.userFormMode == 'add') {
        if (!that.userFormData.password || !that.userFormData.repassword) {
          that.$message('请填写密码');
          return
        }
        var data = {
          username: that.userFormData.accountName,
          role: [that.userFormData.role],
          password: that.userFormData.password,
          phone: that.userFormData.phone
        }
        var url = 'api/admin/user/insertUser'
      }
      if (this.userFormMode == 'edit') {
        var data = {
          userId: that.userFormData.userId,
          username: that.userFormData.accountName,
          role: [that.userFormData.role],
          phone: that.userFormData.phone
        }
        if (that.userFormData.password && that.userFormData.password == that.userFormData.repassword) {
          data.password = that.userFormData.password
        }
        var url = 'api/admin/user/updateUser'
      }
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that
        .$axiosAdmin({
          method: "post",
          url: url,
          data: JSON.stringify(data),
        })
        .then((res) => {
          console.log("onSaveUserFormData =>", res);
          that.getUserListData()
          that.userFormClose()
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 加载项目列表数据
    getProjectList: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        fealg: false
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/project/projectSearch",
          data: JSON.stringify(data),
        })
        .then((res) => {
          console.log('getProjectList =>', res);
          that.projectList = res.data.projectList;
          // res.data.projectList.forEach((item, index) => {
          //   if (item.proName == '添加用户默认项目') {
          //     console.log('添加用户默认项目 index =>', index);
          //     res.data.projectList.splice(index, 1)
          //   }
          // })
          that.getUserListData()
          setTimeout(() => {
            that.handleProjectReset()
          }, 100);
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 打开项目绑定窗口
    userProjectOpen: function (row) {
      this.getProjectList()
      this.userProjectShow = true
      this.userFormData = row
      this.projectSelection = []
    },
    // 关闭项目绑定窗口
    userProjectClose: function () {
      this.userProjectShow = false
      // this.projectList = null
      this.userFormData = {
        userId: null,
        accountName: null,
        role: null,
        password: null,
        repassword: null,
        phone: null,
        menuPermission: null,
      }
      this.projectSelection = []
    },
    // 项目绑定多选控制
    handleProjectChange:function (val) {
      this.projectSelection = val;
    },
    // 项目绑定返显
    handleProjectReset: function () {
      // projectTable
      this.userFormData.projectIdList.forEach(a => {
        this.projectList.forEach((b, index) => {
          if (a == b.id) {
            console.log('handleProjectReset index =>', index)
            this.$refs.projectTable.toggleRowSelection(this.projectList[index]);
          }
        })
      })
    },
    // 保存用户项目权限
    userProjectSave: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        userId: that.userFormData.userId,
        projectIdList: []
      };
      this.projectSelection.forEach(item => {
        data.projectIdList.push(item.id)
      });
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/user/updateProjectUser",
          data: JSON.stringify(data),
        })
        .then((res) => {
          console.log('userProjectSave =>', res);
          that.userProjectClose()
          that.getUserListData()
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 打开菜单权限窗口
    menuPermissionOpen: function (row) {
      this.menuPermissionShow = true
      setTimeout(() => {
        if (this.currentRow.menuPermission == 'null' || this.currentRow.menuPermission == '' || !this.currentRow.menuPermission) {
          this.menuPermissionData = JSON.parse(JSON.stringify(this.menuPermissionData))
        } else {
          this.menuPermissionData = JSON.parse(this.currentRow.menuPermission)
        }
      }, 100);
    },
    // 关闭菜单权限窗口
    menuPermissionClose: function () {
      this.menuPermissionShow = false
      this.menuPermissionData = {
        Home: true,
        Project: {
          ProjectList: true,
        },
        Organization: {
          Unit: false,
          Department: false,
          Personnel: false,
        },
        Attendance: {
          AttendancePerson: false,
          AttendanceConfig: false,
          WorkTimeList: false,
          SetWorkTime: false,
          QueryDay: false,
          QueryMonth: false,
        },
        Device: {
          TowerCrane: false,
          DischargingPlatform: false,
          Elevator: false,
          ElectricityMonitoring: false,
          DustMonitoring: false,
          IntelligenceEye: false,
        },
        Video: {
          VideoList: false,
        },
        Message: {
          MessageList: false,
        },
        System: {
          MyConfig: true,
          SysUser: false,
        },
        Login: true
      }
    },
    // 保存菜单权限数据
    menuPermissionSave: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        userId: that.currentRow.userId,
        username: that.currentRow.userName,
        menuPermission: JSON.stringify(that.menuPermissionData)
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/user/updateUser",
          data: JSON.stringify(data),
        })
        .then((res) => {
          console.log('menuPermissionSave =>', res);
          that.menuPermissionClose()
          that.getUserListData()
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 初始化菜单权限数据
    menuPermissionInit: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        userId: that.currentRow.userId,
        username: that.currentRow.userName,
        menuPermission: JSON.stringify(that.menuPermissionData)
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/user/updateUser",
          data: JSON.stringify(data),
        })
        .then((res) => {
          console.log('menuPermissionInit =>', res);
          that.menuPermissionClose()
          that.getUserListData()
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 判断是否当前登录用户
    isCurrentUser: function (row) {
      if (row.userId == localStorage.getItem('id')) {
        return true
      } else {
        return false
      }
    },
    // 过滤默认项目
    projectListFilter: function (row, index) {
      console.log('projectListFilter =>', row);
      if(row.proName == '添加用户默认项目'){
           return false
       }else {
           return true
       }
    }
  },
};
</script>