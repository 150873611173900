<template>
  <div class="system-menu">
    <div class="main-menu">
      <router-link
        v-if="menuPermission.System.MyConfig"
        :to="{ name: 'MyConfig' }"
        :class="[
          $route.name == 'MyConfig' ? 'child-menu-active' : '',
        ]">个人设置
      </router-link>
      <router-link
        v-if="menuPermission.System.SysUser"
        :to="{ name: 'SysUser' }"
        :class="[
          $route.name == 'SysUser' ? 'child-menu-active' : '',
        ]">用户管理
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SystemMenu',
  props: {
  },
  data() {
    return {
    };
  },
  computed: {
    menuPermission: function () {
      return JSON.parse(localStorage.getItem("menuPermission"));
    },
  },
}
</script>

<style lang="less" scoped>
@link-color: #2c85d8;

.system-menu {
  width: 1200px;
  margin: 0 auto;
  a {
    display: inline-block;
    padding: 10px 20px;
  }
}
</style>
